<template>
  <div class="form">

    <div class="header">
      <div class="logo"></div>
      <span>
        <h4>Street Meet &amp; Partners</h4>
        <h1>Influencer Ad</h1>
      </span>
    </div>

    <form v-if="showForm" @submit.prevent="submit">

      <h3>Name {{ isRequired( 'name' )}}</h3>
      <input type="text" v-model="name" :maxlength="rules.name.max"/>

      <h3>Phone {{ isRequired( 'phone' )}}</h3>
      <input type="text" v-model="phone" :maxlength="rules.phone.max" :class="{ invalid: !validPhone }" placeholder="+44 7361 023 371"/>

      <h3>Platform {{ isRequired( 'platform' )}}</h3>
      <input type="text" v-model="platform" :maxlength="rules.platform.max" placeholder="Instagram"/>

      <h3>Handle {{ isRequired( 'handle' )}}</h3>
      <input type="text" v-model="handle" :maxlength="rules.handle.max" placeholder="@"/>

      <h3>Advertisement Cost {{ isRequired( 'cost' )}}</h3>
      <input type="text" v-model="cost" :maxlength="rules.cost.max" :class="{ invalid: !validCost }" placeholder="£100"/>

      <h3>Publish Date {{ isRequired( 'published' )}}</h3>
      <input type="date" v-model="published"/>

      <h3>Notes {{ isRequired( 'notes' )}}</h3>
      <textarea type="text" v-model="notes" :maxlength="rules.notes.max"/>

      <h2>Sign up</h2>

      <h3>Email {{ isRequired( 'email' )}}</h3>
      <input type="text" v-model="email" :maxlength="rules.email.max" :class="{ invalid: !validEmail }"/>

      <h3>Create password {{ isRequired( 'password' )}}</h3>
      <input type="password" v-model="password" :maxlength="rules.password.max" :class="{ invalid: !validPassword }"/>
      <h5>Requires 1 Uppercase, 1 number &amp; 8 characters</h5>

      <button role="button" alt="Submit" :class="{ disabled: !canSubmit }" class="submit">
        <span class="shadow"></span>
        <span class="edge"></span>
        <span class="content"><p>Submit</p></span>
      </button>

    </form>

    <!-- Error and Loading -->
    <div v-else class="retry">
      <div>

        <h3 v-if="finished" class="bk">{{ error ? error : 'Success' }}</h3>
        <Loading v-if="loading"/>

      </div>
    </div>

  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import { mapActions } from 'vuex'

export default {
  name: 'PartnersInfluencer',

  data() {
    return {
      loading: false,
      error: null,
      finished: false,

      fields: [ 'name', 'email', 'phone', 'platform', 'handle', 'cost', 'published', 'notes', 'password' ],

      name: '',
      email: '',
      phone: '',
      platform: '',
      handle: '',
      cost: '',
      published: '',
      notes: '',
      password: '',

      rules: {
        phone: {
          max: 16,
        },
        cost: {
          max: 16,
        },
        notes: {
          max: 512,
          required: false
        }
      },

      default: {
        content: '',
        max: 255,
        required: true
      },

      validations: {
        email: v => v && this.$Utils.Validation.email( v ),
        phone: v => v && v.toUpperCase() === v.toLowerCase(),
        cost: v => v && v.toUpperCase() === v.toLowerCase(),
        password: v => v && v.match( /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/ )
      }
    }
  },
  
  metaInfo() {
    return {
      title: this.$router.options.title( this.$route )
    }
  },

  components: {
    Loading
  },

  methods: {
    ...mapActions( 'Partners', {
      post: 'submit'
    }),

    async submit() {
      if ( !this.canSubmit ) return
      
      this.loading = true
      this.finished = false
      this.error = null

      let fields = {}
      this.fields.forEach( f => fields[ f ] = this[ f ])
      await this.post( fields ).catch( e => this.error = `${ e } - Please try again` )

      this.loading = false
      this.finished = true
    },

    isValid( f ) {
      return this.validations[ f ]( this[ f ])
    },

    isRequired( f ) {
      return this.rules[ f ].required ? '*' : ''
    }
  },

  computed: {
    completed() {
      const isComplete = f => !this.rules[ f ].required || !!this[ f ]
      return this.fields.map( isComplete ).filter( Boolean ).length === this.fields.length
    },

    valid() {
      const isValid = f => !this.validations[ f ] || !!this.validations[ f ]( this[ f ])
      return this.fields.map( isValid ).filter( Boolean ).length === this.fields.length
    },

    validEmail() {
      return !this.email || this.isValid( 'email' )
    },

    validPhone() {
      return !this.phone || this.isValid( 'phone' )
    },

    validCost() {
      return !this.cost || this.isValid( 'cost' )
    },

    validPassword() {
      return !this.password || this.isValid( 'password' )
    },

    canSubmit() {
      return this.completed && this.valid
    },

    showForm() {
      return !this.finished && !this.error && !this.loading
    }
  },

  beforeMount() {
    this.fields.forEach( f => {
      const rules = this.rules[ f ] || {}
      this.rules[ f ] = { ...this.default, ...rules }
    })
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

button {
  margin-top: 24px;
}

.header {
  @include flex-center;
  padding: 8px;

  span {
    padding: 0 0 0 16px;

    h1 {
      text-align: left;

      @media ( max-width: $tablet ) {
        font-size: 22px;
      }
    }
  }

  h4 {
    text-align: left;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 14px;

    @media ( max-width: $tablet ) {
      font-size: 11px;
    }
  }

  .logo {
    width: 104px;
    height: 104px;
    position: relative;
    background-image: url( '~@/assets/images/logo_circle.png' );
    @include image-center;
    @include flex-center;

    @media ( max-width: $tablet ) {
      width: 104px;
      height: 104px;
    }
  }
}
</style>